import { settings } from '../libraries/settings';
import { auth } from '../libraries/auth';
import { db } from '../libraries/db';

import { toast } from '../libraries/toast';
import { hideLoader, tools } from '../libraries/tools';
import { forms } from '../libraries/forms';

import '../libraries/form';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

App.controller('profile', function (page, data) {
	auth().checkToken();

	// Forms
	page.querySelector('.form-account-wrap').formJ(forms.account, { id: 'account', classes: 'rounded' });
	page.querySelector('.form-application-wrap').formJ(forms.application, { id: 'application', classes: 'rounded' });
	page.querySelector('.form-profile-wrap').formJ(forms.profile, { id: 'profile', classes: 'rounded' });
	page.querySelector('.form-emergency-wrap').formJ(forms.emergency, { id: 'emergency', classes: 'rounded' });
	page.querySelector('.form-studies-wrap').formJ(forms.studies, { id: 'studies', classes: 'rounded' });

	page.addEventListener('appReady', function () {
		gtag('event', 'screen_view', { screen_name: 'Cuenta' });

		tools().showSupportNav(page);

		// Avatar
		db().getTrip().then(function (trip) {
			let application = trip.application;
			if (application.member_avatar) {
				let avatar_url = [settings.contentUrl, 'participants', application.member_id, application.member_avatar + '?' + moment().format('X')].join('/');
				page.querySelector('.profile-avatar').style.backgroundImage = `url('${avatar_url}')`;
			}
		});

		page.querySelectorAll('.upload-avatar-trigger').forEach(el => {
			el.addEventListener('click', function () {
				page.querySelector('.type-' + this.dataset.type).click();
			});
		});

		page.querySelectorAll('.upload-file-input')
			.forEach(el => {
				el.addEventListener('change', function () {
					page.classList.add('app-block-touch');
					toast().show('<span>Subiendo Foto de Perfil</span><span class="file-1">0%</span>', 'info', true, false, 'uploading d-flex justify-content-between');

					let formData = new FormData();
					formData.append('file', this.files[0]);
					var ajax = new XMLHttpRequest();

					ajax.upload.addEventListener("progress", function (e) {
						if (e.lengthComputable) {
							let status = e.loaded / e.total;
							status = parseInt(status * 100);
							document.querySelector('.app-toast.uploading span.file-1').innerText = status + '%';
						}
					}, false);

					ajax.addEventListener("load", function (e) {
						page.classList.remove('app-block-touch');
						document.querySelector(".app-toast.uploading").remove();

						let response = JSON.parse(this.response);
						toast().show(response.html, response.class);
						App.load('application', { tripId: db().getCurrentTripId() });
					}, false);

					ajax.addEventListener("error", function () {
						page.classList.remove('app-block-touch');
						document.querySelector('.app-toast.uploading span.file-1').innerText = 'Error';
					}, false);

					ajax.open('POST', settings.restUrl + '/member/' + settings.member.member_id + '/avatar');
					ajax.setRequestHeader("X-WETAG-Token", settings.token);
					ajax.send(formData);

				});
			});


		// Forms
		let memberId = data.memberId || false;
		db().getMember(memberId).then(function (data) {
			page.querySelector('.account-form').fillJ(data);
			page.querySelector('.profile-form').fillJ(data);
			page.querySelector('.emergency-form').fillJ(data);

			db().getTrip().then(function (trip) {
				page.querySelector('.application-form').fillJ(trip.application);
				page.querySelector('.studies-form').fillJ(trip.application);

				hideLoader(page.querySelector('.app-loader'));
			});
		});

		page.querySelectorAll('.save-profile-button')
			.forEach(el => {
				el.addEventListener('click', function () {
					this.closest('form').dispatchEvent(new Event('submit'));
				});
			});

		page.querySelectorAll('.account-form, .profile-form, .emergency-form')
			.forEach(el => {
				el.addEventListener('submit', function (e) {
					e.preventDefault();
					e.stopPropagation();

					if (this.isValidJ()) {
						toast().show('Guardando Perfil', 'warning', 2000);

						fetch(settings.restUrl + '/member/' + settings.member.member_id, {
							method: 'POST',
							headers: {
								'X-WETAG-Token': settings.token,
								'Content-Type': 'application/x-www-form-urlencoded'
							},
							body: this.serialize()
						})
							.then((response) => {
								return response.json();
							})
							.then((response) => {
								db().getMember(settings.member.member_id);
								toast().show(response.html, response.class);
							});
					}

				});
			});

		page.querySelectorAll('.application-form, .studies-form')
			.forEach(el => {
				el.addEventListener('submit', function (e) {
					e.preventDefault();
					e.stopPropagation();

					if (this.isValidJ()) {
						toast().show('Guardando Perfil', 'warning', 2000);

						fetch(settings.restUrl + '/application/' + settings.trip.application.application_id, {
							method: 'POST',
							headers: {
								'X-WETAG-Token': settings.token,
								'Content-Type': 'application/x-www-form-urlencoded'
							},
							body: this.serialize()
						})
							.then((response) => {
								return response.json();
							})
							.then((response) => {
								db().getMember(settings.member.member_id);
								toast().show(response.html, response.class);
							});
					}
				});
			});

		// Applications
		db().getApplications().then(function (data) {
			db().getCurrentTripId().then(function (currentTripId) {

				let applicationsListWrap = page.querySelector('.applications-list-wrap');
				let applicationsList = page.querySelector('.applications-list');
				let applicationNode = document.querySelector('#node-application-item');

				if (Object.keys(data).length > 0) {
					let titleApps = document.createElement('h3');
					titleApps.className = 'text-black ms-3 mb-2 mt-5 fs-5';
					applicationsListWrap.insertBefore(titleApps, applicationsList);

					let applicationsByDate = Object.values(data).sort(
						function (a, b) {
							let c = moment(a.date),
								d = moment(b.date);
							return d - c;
						}
					);

					applicationsByDate.forEach(function (application) {
						let item = applicationNode.content.cloneNode(true);
						item.firstElementChild.setAttribute('data-id', application.application_id);

						item.querySelector('.card-title')
							.innerHTML = "<b class='program-name'>" + application.program_label + "</b> / <small>Opción</small> <b>" + application.subprogram_label + "</b>";

						item.querySelector('.card-details')
							.innerHTML = "Año <b>" + application.year + "</b> / Paso <b>" + application.step_label + "</b>";

						item.firstElementChild.addEventListener('click', function (e) {
							e.stopPropagation();
							let tripId = this.dataset.id;

							toast().show('Cambiando de Aplicación', 'warning', 2000);
							db().setCurrentTripId(tripId);
							db().getTrip(tripId).then(function () {
								App.load('application');
							});
						});

						if (currentTripId == application.application_id) {
							item.querySelector('section').classList.add('bg-yellow-subtle');
						}

						applicationsList.append(item);
					});
				}
			});
		});

		// Version
		page.querySelector('.version-info').innerHTML = 'Versión ' + settings.version;

		// Logout
		page.querySelector('.button-logout').addEventListener('click', function () {
			App.dialog({
				title: 'Cerrar Sesión?',
				okButton: 'Aceptar',
				cancelButton: 'Cancelar',
			}, function (confirmed) {
				if (confirmed) auth().deleteToken();
			}
			);
		});

		// Reinstall
		page.querySelector('.button-reinstall').addEventListener('click', function () {
			App.dialog({
				title: 'Reinstalar la Aplicación',
				okButton: 'Aceptar',
				cancelButton: 'Cancelar',
			}, function (confirmed) {
				if (confirmed) {
					if ('serviceWorker' in navigator) {
						caches.delete(settings.version);
						navigator.serviceWorker
							.getRegistrations()
							.then(function (registrations) {
								for (let registration of registrations) {
									registration.unregister();
								}
								window.location.replace(window.location.href);
							});
					}
				}
			}
			);
		});

	});
});
