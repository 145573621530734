import { settings } from '../libraries/settings';
import { auth } from '../libraries/auth';

import { toast } from '../libraries/toast';

import gtag from 'ga-gtag';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

import PullToRefresh from 'pulltorefreshjs';
import { hideLoader } from '../libraries/tools';

App.controller('esim', function (page, data) {
    auth().checkToken();

    page.addEventListener('appReady', function () {
        gtag('event', 'screen_view', { screen_name: 'eSim' });
        hideLoader(page.querySelector('.app-loader'));

        let listEsimPackage = page.querySelector('.esim-package-list');
        let nodeEsimPackageItem = document.querySelector('#node-esim-package-item');
        let plans = {
            'US_3_Daily': 6,
            'US_10_Daily': 16,
            'US_5_30': 10,
            'US_10_30': 15,
            'US_20_90': 30,
            'US_50_180': 60
        };

        fetch('list.json')
            .then((response) => {
                return response.json();
            })
            .then((list) => {
                console.log(list);
                Object.values(list.obj.packageList).forEach((row) => {
                    if (Object.keys(plans).includes(row.slug)) {

                        let item = nodeEsimPackageItem.content.cloneNode(true);
                        item.querySelector('.card-title').innerHTML = row.name;
                        item.querySelector('.card-price').innerHTML = '$' + plans[row.slug];
                        //item.querySelector('.card-details').innerHTML = row.retailPrice;

                        listEsimPackage.append(item);
                    }
                });
            });


        PullToRefresh.init({
            mainElement: '.app-content',
            triggerElement: '.page-esim',
            instructionsPullToRefresh: 'Arrastrá hacia abajo para actualizar',
            instructionsReleaseToRefresh: 'Soltá para actualizar',
            instructionsRefreshing: 'Actualizando',
            onRefresh() {
                App.load('esim');
            },
            shouldPullToRefresh: function () {
                return !this.mainElement.scrollTop;
            }
        });

    });

});