import { auth } from '../libraries/auth';
import { toast } from '../libraries/toast';

import { gtag, install } from 'ga-gtag';

import localforage from 'localforage/dist/localforage.min.js';

// Google Analytics
install('G-Z1TCCW3ELM', { 'send_page_view': false });

// Storage
localforage.config({
    driver: localforage.INDEXEDDB,
    name: 'WETAG',
    version: 1.0,
    storeName: 'main'
});

// Callback Loader
setTimeout(() => {
    let loader = document.querySelector('.app-loader');
    if (loader && loader.offsetParent !== null) {
        setTimeout(() => {
            let loader = document.querySelector('.app-loader');
            if (loader && loader.offsetParent !== null) {
                toast().show(
                    'Algo no está funcionando correctamente, volvé a iniciar sesión haciendo clic acá.',
                    "warning",
                    5000,
                    function () {
                        auth().deleteAll();
                    }
                );
            }
        }, 15000);
    }

}, 15000);

// Online / Offline
window.addEventListener('online', () => {
    let offlineToast = document.querySelector('.toast-offline');
    if (offlineToast) {
        offlineToast.remove();
    }

    document.body.classList.remove('app-offline');
    document.body.classList.add('app-online');

    App.load('application');
});

window.addEventListener('offline', () => {
    document.body.classList.remove('app-online');
    document.body.classList.add('app-offline');

    toast().show(
        'No estás conectado a internet. Algunas acciones pueden estar desactivadas.',
        "warning",
        true,
        false,
        'toast-offline'
    );
});
if (!navigator.onLine) document.body.classList.add('app-offline');


window.addEventListener('beforeinstallprompt', (event) => {
    event.userChoice.then((response) => {
        gtag('event', 'add_to_home', {
            value: response.outcome
        });
    });
});

document.addEventListener('appReady', (e) => {
    let appDropdownElements = document.querySelectorAll('.app-dropdown:not(.trigger-loaded)');
    if (appDropdownElements) {
        appDropdownElements.forEach((el) => {
            el.classList.add('trigger-loaded');
            el.addEventListener('click', (e) => {
                if (e.target.classList.contains('app-dropdown')) {
                    e.target.classList.toggle('open');
                } else {
                    e.target.offsetParent.classList.toggle('open');
                }
            });
        });
    }

    let appCollapseBtns = document.querySelectorAll('.app-collapse .app-button:not(.trigger-loaded)');
    if (appCollapseBtns) {
        appCollapseBtns.forEach((el) => {
            el.classList.add('trigger-loaded');
            el.addEventListener('click', (e) => {
                e.target.parentNode.classList.toggle('open');
            });
        });
    }
});

